@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.searching_cardbox {
  cursor: pointer;
  color: rgb(4, 4, 5);
  transition: all 0.12s ease-in-out 0s;
  text-align: left;
  min-height: 35px;
  padding: 8px 12px;
  margin: 0px 12px;
  border-radius: 6px;
}

.searching_box {
  flex-direction: row;
  align-items: center;
  vertical-align: inherit;
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
}

.searching_titlebox {
  padding: 8px 24px;
  text-align: left;
}

.searching_details {
  flex-shrink: 1;
  flex-grow: 1;
  margin-left: 20px;
}

.searching_listbox {
  margin-bottom: 16px;
  vertical-align: inherit;
  max-width: 100%;
  min-height: 0px;
  min-width: 0px;
  flex-shrink: 0;
  flex-direction: column;
  flex-basis: auto;
  display: flex;
  align-items: stretch;
}