@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.sub_menu_arrow {
    justify-content: end !important;
}

.menuItems {

    .menuDetails,
    a {
        display: flex !important;
        justify-content: space-between;
        margin: 19px 19px 19px 10px;
        font-size: 18px;
        font-family: Jost;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0.4px;
        color: var(--text-primary, #333);
        text-align: left;
    }
}

.btn {
    width: 100%;
    // margin: 36px 36px 36px 36px;
}

.btnMargin {
    margin: 19px 19px 19px 10px;
}

.btnMargin1 {
    margin: 0px 19px 19px 0px;
}

.btns {
    
    width: 100%;
    // margin: 10px 30px 10px 10px !important;

    .btn__clr {
        &:hover {
            background-color: #7557BB;
            color: white;
        }
    }
}

.btn__clr {
    &:hover {
        background-color: #7557BB !important;
        color: white !important;
    }
}

.profileMainDiv {
    background-color: var(--text-4, #F4F4F4);

    .menuDetails1,
    a {
        display: flex !important;
        font-size: 18px;
        font-family: Jost;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0.4px;
        color: var(--text-primary, #333);
        text-align: left;
    }
}

.img_section {
    max-width: 28px;

    .author__avatar {
        img {
            border-radius: 40px;
        }

        .author__avatar__verified {
            content: "";
            position: absolute;
            display: flex;
            width: 16px;
            height: 16px;
            top: 4px;
            right: -2px;
            background-size: 14px auto;
            z-index: 100;
            pointer-events: none;
        }
    }

}

.bg_profile {
    position: relative;
    width: 46px;

    svg {
        max-height: 50px !important;
        max-width: 46px !important;
    }
}

.user_img {
    position: absolute;
    max-height: 25px;
    top: 50%;
    transform: translate(35%, -50%);
}

.level_img {
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 0px;
    text-align: center;

    svg {
        min-height: 12px !important;
        min-width: 36px !important;
        max-width: 36px !important;
    }
}

.level_text {
    display: block !important;
    position: absolute;
    font-weight: 500;
    font-size: 9.21404px;
    line-height: 10px;
    text-align: center;
    color: #FFFFFF;
    bottom: 9px;
    width: 100%;
    left: 0px;
    right: 0;
}

.textHidden {
    display: inline-block !important;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 28px;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /* 165% */
}