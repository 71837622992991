@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
$border_color: #cccccc;

.btn {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0em;
  font: $primary_font;

  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
}

.sm {
  padding: 4px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
}

.md {
  padding: 8px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  font-style: normal;
}

.lg {
  font-weight: 500;
  text-align: center;
  font-style: normal;

  letter-spacing: 0.02em;

  @include responsive-up(lg) {
    padding: 16px 32px;
    font-size: 18px;
    line-height: 30px;
  }
}

.primary {
  // padding: 8px 24px;
  background: $primary_color;
  border-radius: 4px;
  font-family: $primary_font;
  color: #ffffff;
  &:hover {
    color: $primary_color;
    border: 1px solid $border_color;
    background: transparent;
  }
}

.secondary {
  // padding: 8px 24px;
  background: $border_color;
  border: 1px solid $border_color;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // text-align: center;
  // letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    color: $border_color;
    background: transparent;
  }
}

.primary_outline {
  padding: 8px 20px;
  background: transparent;
  border: 1px solid $primary_color;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;

  // font-style: normal;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 32px;
  // text-align: center;
  // letter-spacing: 0.02em;
  color: $primary_color;

  &:hover {
    color: #ffffff;
    background: $primary_color;
  }
}

.secondary_outline {
  // padding: 8px 24px;
  background: transparent;
  border: 1px solid $border_color;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // text-align: center;
  // letter-spacing: 0.02em;
  color: $primary_color;

  &:hover {
    color: #ffffff;
    background: $primary_color;
  }
}

.secondary_outline_light {
  // padding: 8px 24px;
  background: transparent;
  border: 1px solid $border_color;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // text-align: center;
  // letter-spacing: 0.02em;
  color: #666666;

  &:hover {
    color: $primary_color;
    border: 1px solid $primary_color;
    background: transparent;
  }
}

.secondary_nooutline {
  // padding: 8px 24px;
  background: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;
  color: #666666;

  &:hover {
    color: $primary_color;
    border: 1px solid $border_color;
    background: transparent;
  }
}


.white {
  background: #ffffff;
  border: 1px solid $border_color;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $primary_font;
  color: $primary_color;

  &:hover {
    color: #ffffff;
    border: 1px solid #ffffff;
    background: transparent;
  }
}

.warning {
  // padding: 8px 24px;
  background: #FF7900;
  border-radius: 4px;
  font-family: $primary_font;
  border: 1px solid #FF7900;
  color: #ffffff;
  &:hover {
    color: #FF7900;
    border: 1px solid $border_color;
    background: #ffffff;
  }
}